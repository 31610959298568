import React, { lazy } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";

// import AspirantPage from "./AspirantPage";
// import AspirantProfile from "./AspirantProfile"
// import AspirantResults from "./AspirantResults";
// import AspirantInterviewReport from "./AspirantInterviewReport";
// import HomePage from "./HomePage";
// import ForceLogin from "./ForceLogin";
// import AspirantSetProfile from "./AspirantSetProfile";
// import OrganizationProfile from "./OrganizationProfile";
// import OrganizationCreateCandidateProfiles from "./OrganizationCreateCandidateProfiles";
// import OrganizationManageJobs from "./OrganizationManageJobs";
// import OrganizationPage from "./OrganizationPage";
// import OrganizationJobPage from "./OrganizationJobPage";
// import OrganizationManageCandidates from "./OrganizationManageCandidates";
// import AspirantError from "./AspirantError";
// import OrganizationError from "./OrganizationError";
// import AspirantJobInterview from "./AspirantJobInterview";
// import AspirantURLInterview from "./AspirantURLInterview";
// import AspirantCompanyInterviewV2 from "./AspirantCompanyInterviewV2";
// import Feedback from "./Feedback";
// import FullFeedback from "./FullFeedback";
// import AspirantLinkedinInterview from "./AspirantLinkedinInterview";
// import OrganizationInterviewConfig from "./OrganizationInterviewConfig";
// import OrganizationInterviewReport from "./OrganizationInterviewReport";
// import ACSCall from "./ACSCall";

// import AspirantHomePage from "./AspirantHomePage";
// import OrganizationHomePage from "./OrganizationHomePage";
// import WWWPage from "./WWWPage";
// import PossoError from "./PossoError";


const AspirantHomePage = lazy(() => import('./AspirantHomePage'))
const OrganizationHomePage = lazy(() => import('./OrganizationHomePage'))
const WWWPage = lazy(() => import('./WWWPage'))
const PossoError = lazy(() => import('./PossoError'))

// import { PossoCheckLogin } from './FirebaseAuth';
// import { ProtectedRoutesAspirant, ProtectedRoutesOrganization } from "./ProtectedRoutes";


function App() {

  let homepage = <WWWPage />

  if (window.location.hostname === 'smarthire.possobuild.ai') {
    homepage = <OrganizationHomePage />
  }
  else if (window.location.hostname === 'smartprep.possobuild.ai') {
    homepage = <AspirantHomePage />
  }


  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path='/' element={homepage} errorElement={<PossoError />} />
      <Route path='/aspirant' element={<AspirantHomePage />} errorElement={<PossoError />} />
      <Route path='/organization_home' element={<OrganizationHomePage />} errorElement={<PossoError />} />
    </>
  ))

  return <>
    <React.Suspense fallback={<p>Loading...</p>}>
      <RouterProvider router={router} />
    </React.Suspense>
  </>
};

export default App;